import React from 'react'
import { Query } from '@/types/graphql-types'
import { graphql, PageRendererProps } from 'gatsby'
import SEO from '@/components/Seo'

interface Props extends PageRendererProps {
  pageContext: any
  data: Query
  location: any
}

export const pageQuery = graphql`
  query MyQueryText($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date
        description
      }
    }
  }
`

// This is sample code.
const Post = ({ data, location, pageContext }: Props) => {
  let doc: HTMLDivElement | null = null

  const md = data.markdownRemark!
  const html = md.html!
  const title = md.frontmatter!.title!
  const description = md.frontmatter!.description as string

  const { breadcrumbs } = pageContext

  return (
    <div className="bg-gray-100 ">
      <SEO title="Agreement" description={description} />

      <div className="min-h-screen h-full flex-grow mx-auto md:max-w-6xl mt-4">
        <div className="h-full">
          <div className="h-full bg-white w-full p-1 md:p-8">
            <h1 id="h-0" className="text-2xl md:text-4xl mb-4 font-bold">
              {title}
            </h1>
            <div
              className="blog"
              ref={(content) => (doc = content)}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Post
